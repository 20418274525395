import * as React from "react";
import { Button, Divider, Icon, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

interface ISongStory {
  title: string;
  album: string;
  artist: string;
  cover_image: string;
  story_text?: any;
  lyrics?: any;
  storyTopRef: any;
  prevUrl: string;
  nextUrl: string;
  bc_scr: string;
  albumUrl: string;
  scrollTo: string;
  setScrollToRef(toRef: string): void;
}

const SongStory: React.FC<ISongStory> = ({
  album,
  artist,
  cover_image,
  story_text,
  lyrics,
  storyTopRef,
  bc_scr,
  albumUrl,
  scrollTo,
  setScrollToRef,
  title,
  prevUrl,
  nextUrl,
}) => {
  const playerTitle = album + " by " + artist;
  return (
    <div className="column">
      <div ref={storyTopRef} />
      <div className="breadcrumb">
        <NavLink to="/" onClick={() => setScrollToRef(scrollTo)}>
          BACK TO HOME
        </NavLink>
      </div>
      <h1 className="title">{title}</h1>
      <Image src={cover_image} size="large" centered />
      <Divider hidden />
      <div className="song_player">
        <iframe
          title={playerTitle}
          className="bandcamp_song"
          src={bc_scr}
          seamless
        >
          <a href={albumUrl}>
            {album} - by {artist}
          </a>
        </iframe>
      </div>

      <div className="song_content">
        <div className="text_block">{story_text}</div>
        {lyrics && <h2 className="title">Lyrics</h2>}
        <div className="text_block">{lyrics}</div>
      </div>

      <div className="song_nav">
        {prevUrl !== "" && (
          <NavLink to={prevUrl} onClick={() => setScrollToRef("story_top")}>
            <Button icon labelPosition="left" floated="left">
              Prev Chapter
              <Icon name="arrow left" />
            </Button>
          </NavLink>
        )}

        {nextUrl !== "" && (
          <NavLink to={nextUrl} onClick={() => setScrollToRef("story_top")}>
            <Button icon labelPosition="right" floated="right">
              Next Chapter
              <Icon name="arrow right" />
            </Button>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default SongStory;
