import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSoundcloud,
  faSpotify,
  faFacebook,
  faTwitter,
  faYoutube,
  faBandcamp,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const SocialFollow: React.FC = () => {
  const iconSize = "2x";
  return (
    <div className="social_follow">
      <a
        title="Spotify"
        className="spotify"
        href="https://open.spotify.com/artist/3u2Sl3aaZK6XqrDudpUqUC"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faSpotify} size={iconSize} />
      </a>

      <a
        title="Soundcloud"
        className="soundcloud"
        href="https://soundcloud.com/man-from-the-box"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faSoundcloud} size={iconSize} />
      </a>

      <a
        title="Bandcamp"
        className="bandcamp"
        href="https://manfromthebox.bandcamp.com/"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faBandcamp} size={iconSize} />
      </a>

      <a
        title="Facebook"
        className="facebook"
        href="https://www.facebook.com/manfromthebox/"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} size={iconSize} />
      </a>

      <a
        title="Youtube"
        className="youtube"
        href="https://www.youtube.com/channel/UC2goGbyYNulV07HhJYh59nw"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faYoutube} size={iconSize} />
      </a>

      <a
        title="Twitter"
        className="twitter"
        href="https://twitter.com/ManfromtheBox"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} size={iconSize} />
      </a>

      <a
        title="Instagram"
        className="instagram"
        href="https://www.instagram.com/man_from_the_box/"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} size={iconSize} />
      </a>
    </div>
  );
};

export default SocialFollow;
