import * as React from "react";

interface IYtPlayer {
  url: string;
}
const YtPlayer: React.FC<IYtPlayer> = ({ url }) => {
  return (
    <div className="video">
      <iframe
        width="100%"
        height="315"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YtPlayer;
