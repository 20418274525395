import * as React from "react";
import "./App.scss";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Divider, Image } from "semantic-ui-react";
import mftb_header from "./images/fb-header_xl.jpg";
import mft_header_mobile from "./images/mftb_image3.jpg";
import SocialFollow from "./components/SocialFollow";
import SongStory from "./components/SongStory";

import Home from "./components/Home";

import wbb from "./images/cover_WasBetterBefore.png";
import wpwt from "./images/cover_wpwt.jpg";
import us from "./images/cover_us.png";
import lr from "./images/cover_lr.png";
import v from "./images/cover_v.png";
import i from "./images/cover_i.png";
import acj from "./images/cover_acj.png";

import hlop from "./images/cover_hlop.jpg";
import acm from "./images/cover_acm.jpg";
import da from "./images/cover_da.jpg";
import td from "./images/cover_td.jpg";
import d from "./images/cover_d.jpg";
import rs from "./images/cover_rs.jpg";
import dotma from "./images/cover_dotma.jpg";
import fw from "./images/cover_fw.jpg";
import t from "./images/cover_t.jpg";

function App() {
  const [scrollToRef, setScrollToRef] = React.useState("none");
  const tsoat1Ref = React.useRef<HTMLDivElement>(null);
  const tsoat2Ref = React.useRef<HTMLDivElement>(null);
  const storyTopRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    executeScroll();
  });

  const executeScroll = () => {
    switch (scrollToRef) {
      case "tsoat1":
        if (null !== tsoat1Ref.current) {
          tsoat1Ref.current.scrollIntoView();
        }
        break;
      case "tsoat2":
        if (null !== tsoat2Ref.current) {
          tsoat2Ref.current.scrollIntoView();
        }
        break;
      case "story_top":
        if (null !== storyTopRef.current) {
          storyTopRef.current.scrollIntoView();
        }
        break;
    }
    setScrollToRef("none");
  };

  const wbb_text = (
    <React.Fragment>
      <p>
        David walks happily through his neighborhood. It is a sunny day and
        there is a lot of people around: couples, families, businessmen and
        street food trucks. He kicks a small stone around and then leaves it
        alone. While David walks new buildings pop up over the horizon. He is
        growing up, he’s a teenager now. Other teenagers walk with him. Not too
        happy, but they manage to play with each other. The buildings start to
        decay a bit while new ones make their way up to the skyline.
        <br />
        <br />
        He is a young adult now. The sky changes color. The city has changed. It
        has become a cruel and ruthless creature and David sees it, but does not
        believe it. He silently walks through the streets while he remembers how
        he used to play cheerfully on them. Not anymore. He does not trust them
        anymore.
        <br />
        <br />
        He continues walking and there are less and less people on the streets
        as he goes. Tired and suspicious faces everywhere, who try to reach
        their destination swiftly. Homeless people lying on the sidewalks and
        garbage piling up on every corner. And glimpses of shadows stalking.
        <br />
        <br />
        <span className="dialog">
          “Should I run? Should I be outside alone? Is someone else seeing
          this?!”
        </span>
        <br />
        He’s finally home and opens the door with a slight relief. Goes in and
        kisses his wife and daughter. His son fell asleep a while ago so he just
        gives him a quick kiss on the forehead and smiles bursting with love
        while watching him sleep.
        <br />
        <br />
        After a nice dinner with his family, David clears the table, washes the
        dishes together with his wife and gets ready to go out with some old
        friends. It is Friday after all. His wife is not very happy about it.
        Her face changes drastically and has a tired and worried look in her
        eyes.
        <br />
        <br />
        <span className="dialog">“Please, stay. It is dangerous now.”</span>
        <br />
        <span className="dialog">
          “I’ll be back in a while. I can handle this shit. Love, I’ve done this
          before. I just want to hang out a bit.”
        </span>
        <br />
        David picks up his keys, opens the door and says goodbye. Willing to
        forget that the streets are not cheerful anymore…
      </p>
    </React.Fragment>
  );
  const wbb_lyrics = (
    <React.Fragment>
      <p>
        Silently walking down the streets where
        <br />I once used to play
        <br />
        Trying to avoid now all dark shadows
        <br />
        Trying to make my way
        <br />
        <br />
        Nobody tells me if I gotta run <br />
        Nobody guards my back <br />
        Nobody tells me I might be wrong <br />
        And that's awfully sad (Yeah...)
        <br />
        <br />
        I'll be back in a while <br />I can handle this shit <br />
        Love, I've done this before
        <br />I just want to hang out a bit
      </p>
    </React.Fragment>
  );

  const wpwt_text = (
    <React.Fragment>
      <p>
        It is a clear night sky full of stars. People are going into their
        homes, locking the doors behind them and making sure the windows are
        also closed. David walks through the street, it is dark and decadent.
        The flickering street lights reveal parts of the sidewalk sometimes. The
        stalking shadows are more evident now. They are watching David from a
        distance. Every Time a bit of light strikes the street, they retreat.
        Only to get closer to David when it is dark again.
        <br />
        <br />
        As he walks, a cloak of darkness subtly hides the stars and helps the
        shadows to get closer to David, he turns in a corner, into an alley. It
        is partially lit. A shadow approaches the light and its face is drawn:
        inhuman, cold, with big eyes, cold smile. He approaches David, sniffing
        him like a predator to its prey. David empties his pockets and offers
        everything to the creature. It sees the loot with little interest. He
        grabs them and continues to threaten him. The fear is reflected in
        David’s face, a bony hand caresses his chin. From the now dark sky, The
        City’s face looms over the buildings. It is a face of death. And it is
        ruthless. David looks up in terror. Its hands control the shadows like
        puppets. The shadow that harasses David runs its sharp blade-like
        fingers down his face.
        <br />
        <br />
        <span className="dialog">
          "You’re going nowhere. I’ve got the knife, so stay put and don’t move.
          Guess you like your life"
        </span>
        <br />
        It removes its hand from David’s face and gently pulls his shirt. David
        resists uselessly.
        <br />
        <br />
        <span className="dialog">
          "Just come. I SAID, just come. I’ll take you!”
        </span>
        <br />
        He runs his blades through David’s abdomen and stabs his stomach while
        staring into his eyes. He falls on his knees with blank eyes. Lost. The
        rest of the creatures come out of the shadows as if to feed on their
        prey.
      </p>
    </React.Fragment>
  );
  const wpwt_lyrics = (
    <React.Fragment>
      <p>
        You're going nowhere
        <br />
        I've got the knife
        <br />
        Stay put don't move Guess you like your life
        <br />
        <br />
        Just come
        <br />
        I said just come, I'll take you!
        <br />
      </p>
    </React.Fragment>
  );
  const us_text = (
    <React.Fragment>
      <p>
        David, still on his knees, looks down to his belly. He is bleeding a
        lot. The attacker is looking at him with a silly smile on its face. A
        really cold smile. A circle of shadowy creatures surround both of them,
        like waiting for something.
        <br />
        <br />
        David touches his wound with both hands, puts them in front of his eyes.
        He can’t believe it. Why? His eyes start to fill with tears and anger.
        <br />
        <br />
        <span className="dialog">
          “No!! What have you done to me? Is this my blood?”
        </span>
        <br />
        The creature nods amused and licks its fingers.
        <br />
        <br />
        <span className="dialog">
          “What harm did I to you? Why did you do this to me?”
        </span>
        <br />
        The creature comes closer, the smile is gone, and looks at David
        straight into his eyes. David starts crying anxiously.
        <br />
        <br />
        <span className="dialog">
          “I gave you all I have. What else do you want? Got nothing left on
          me!”
        </span>
        <br />
        The creature stands tall again. For the first time it shows anger in its
        face. It’s mouth gets twisted and saliva starts drooling out while it
        falsely laughs. It gets a bit darker and The City looks at David from
        above. Cold, but curious. The creature touches David’s face.
        <br />
        <br />
        <span className="dialog">
          “You brag all day with all your shiny stuff and you think that I want
          only THAT you have in your pockets?”
        </span>
        <br />
        <span className="dialog">
          “I know something you won't give so easily. How about that little
          rich-boy life of yours.”
        </span>
        <br />
        <span className="dialog">“NO PLEASE… don’t…”</span>
        <br />
        David gets stabbed many times. The circle of shadows come closer and
        start beating him. He struggles for a while. But now everything is calm.
        He stares into the stars again. And, strangely, a light smile is drawn
        in his face. Maybe it is true that your life passes through your eyes
        before you die.
      </p>
    </React.Fragment>
  );
  const us_lyrics = (
    <React.Fragment>
      <p>
        No! What have you done to me!
        <br />
        Is this my blood I can see?!
        <br />
        What harm did I to you?
        <br />
        To in this pain put me through
        <br />
        <br />
        I gave you all I have
        <br />
        What else do you desire
        <br />
        Got nothing left on me
        <br />
        And you stand and watch me bleed
        <br />
        <br />
        (laughs) Don't want only these
        <br />
        You give with some much ease
        <br />
        So many things you have
        <br />
        Need my eyes to brag?
        <br />
        <br />
        So what is your truth
        <br />
        What are you afraid to lose
        <br />
        I'll show you with this knife
        <br />
        Don't wanna give your life
        <br />
      </p>
    </React.Fragment>
  );

  const lr_text = (
    <React.Fragment>
      <p>
        David lies on the floor. Blood is escaping his body slowly and steadily.
        One hand rests on his chest and the other one on the floor. He breathes
        calmly and with a random rhythm. The murderer and its shadows are gone.
        Even The City lost interest in David. He was alone, cold, dying… but in
        peace.
        <br />
        <br />
        Images of his family and friends went through his mind. His wedding day,
        what a party!
        <br />
        <br />
        <span className="dialog">(Smiles weakly)</span>
        <br />
        How his wife kissed him and how she was the perfect fit when they
        cuddled. How making love with her was the most sublime and intense
        feeling he ever had.
        <br />
        <br />
        <span className="dialog">(Coughs, breathes erratically)</span>
        <br />
        Those two small babies. He was so afraid of carrying them when they were
        born. They looked so fragile, so helpless. He thought he would break
        them. How warm they felt when they slept over his chest. He never moved
        till they woke up. Did not want to disturb their sleep.
        <br />
        <br />
        <span className="dialog">(Smiles. A tear escapes his eye)</span>
        <br />
        Now they were bigger. Still fragile though. The independent and
        rebellious daughter. Strong, decided and with a huge sense of humor. The
        sweet musical son, always singing and laughing. Always learning from his
        sister. They will be alright. They are excellent kids…
        <br />
        <br />
        <span className="dialog">
          “How I wish to see them run at me one last time. While they call me
          ‘Dad’. One more hug… I’m sorry. I am so sorry. Wasn’t strong enough.
          And now I’ll leave you on your own...”
        </span>
        <br />
        David’s eyes get bigger. Hardly breathes now. It is so cold. He feels
        the end is coming. It is a strange feeling. He wonders what’s next.
      </p>
    </React.Fragment>
  );
  const lr_lyrics = (
    <React.Fragment>
      <p>
        Can see you in our wedding day
        <br />
        Can feel your kiss and smile
        <br />
        Can picture you in my arms
        <br />
        While we go wild, to ever be
        <br />
        <br />
        Could carry them with just one hand
        <br />
        Afraid that they would break
        <br />
        And now they run to me
        <br />
        With a big smile, and hug their dad
        <br />
        <br />
        And now's all wrong
        <br />
        Won't be going home
        <br />
        Going to leave you alone
        <br />
        <br />
        I fought and I just lost
        <br />
        How could I be so weak
        <br />
        I fell, will not get up
        <br />
        And now things'll never be the same
        <br />
        Again
        <br />
      </p>
    </React.Fragment>
  );

  const v_text = (
    <React.Fragment>
      <p>
        It’s not dark yet. But there’s no light either. Not a light he
        recognizes. The body is buzzing, like if something is trying to get
        squeezed out of his body. It starts in the legs and goes up to his head.
        He can’t move but is completely conscious of his body. The buzz is also
        a sound like a TV with no signal. A sound that forms words in his head.
        He can listen to his name every now and then. Strange bright yellow
        blobs pop up in his mind, they move, they disappear and reappear.
        Different songs run through his head. Really loud. Songs he knew of
        course. He couldn’t stop them from playing.
        <br />
        <br />
        He feels he sees his face, but doesn’t. Feels he knows who he is… but
        doesn’t understand it. The last thread of consciousness is starting to
        vanish.
      </p>
    </React.Fragment>
  );

  const i_text = (
    <React.Fragment>
      <p>
        Some last thoughts run through David’s head. Everything is quiet. The
        mind is at ease. It looks like this is a short last moment.
        <br />
        <br />
        <span className="dialog">
          “Am I going somewhere from here? Will someone pick me up? Guess I’ll
          wait…”
        </span>
        <br />A slight feeling of anger swept his body while a bigger sense of
        curiosity was eager to continue and know what would happen. It felt
        strange to be ready for this. And he was.
      </p>
    </React.Fragment>
  );
  const i_lyrics = (
    <React.Fragment>
      <p>
        I'm waiting for someone to come
        <br />
        We're going to a place I've never gone
        <br />
        <br />
        I don't know whom I'm waiting for
        <br />
        I don't know where I'm going
        <br />
        <br />
        I'm hoping that this someone doesn't come
        <br />
        I'm begging that he comes to go
        <br />
        <br />
        I don't know what I really want
        <br />
        I'm just laying here
        <br />
        <br />
        Waiting
        <br />
        For someone I don't know
        <br />
        We're going
        <br />
        To a place I don't know
        <br />
        <br />
        I know nothing
        <br />
        I know nothing
        <br />
      </p>
    </React.Fragment>
  );

  const acj_text = (
    <React.Fragment>
      <p>
        He went nowhere. There was no light, no darkness. There wasn’t even a
        nothing. He just was no more. The only thing worth loving of this moment
        was that the end was calm and peaceful. He actually felt that he was
        going on a new adventure, a new chapter. And that is a wonderful way to
        go...
        <br />
        <br />
        And loved ones were waiting for him. Probably worried sick by now. It
        was very late.
      </p>
    </React.Fragment>
  );
  const acj_lyrics = (
    <React.Fragment>
      <p>
        The night
        <br />
        Has seen
        <br />
        Too much...
        <br />
      </p>
    </React.Fragment>
  );

  const hlop_story = (
    <React.Fragment>
      <p>
        Sara, David’s wife, sits on the couch facíng the door. The kids are
        still sleeping. It’s almost 4 o’clock in the morning.
        <br />
        <br />
        She tried calling David on the phone many times. Only the answering
        machine talks back. She wants to believe that his battery died. But that
        is not what she really believes. She’s starting to lose control over
        fear.
        <br />
        <br />
        <span className="dialog">
          “Just one call please, let me hear your voice”
        </span>
        <br />
        <span className="dialog">“Please come through that door now”</span>
        <br />
        She tried to convince David to stay home that night. Things are getting
        worse on the streets day by day. But he “knew” what he was doing. He
        never took that long to return home.
        <br />
        <br />
        <span className="dialog">“Where are you”</span>
        <br />
        Every noise made her jump. Now she was staring at her phone. Waiting for
        the sweet melody of it ringing and having David on the other side of a
        conversation.
        <br />
        6:30 a.m. Sara was in the kitchen making some coffee. She called David
        again. And someone answered the phone. Hope filled her body while she
        asked David where he was and if he was ok.
        <br />
        <br />
        <span className="dialog">
          “David? I’m no David… nice phone he had by the way, he won’t be
          needing it anymore. I can give it back to you for a reasonable
          price...”
        </span>
        <br />
        And laughs… the man at the other end of the call just laughed. Sara hung
        up and broke apart...
        <br />
      </p>
    </React.Fragment>
  );

  const hlop_lyrics = (
    <React.Fragment>
      <p>
        The dark wraps the unknown
        <br />
        She's still waiting full of hope
        <br />
        For peace and some joy
        <br />
        The noise she's been wishing for
        <br />
        Staring at the door
        <br />
        Expecting for something to happen
        <br />
        Something must be wrong
        <br />
        Hasn't even got a call
        <br />
        <br />
        A call
        <br />
        A voice
        <br />
        His voice
        <br />
        <br />
        A melody is heard
        <br />
        So beautiful and peaceful
        <br />
        And the fire starts on
        <br />
        And it's warmth is all around
        <br />
        But all the frozen thoughts
        <br />
        Are now all melting
        <br />
        And the sleeping ones
        <br />
        Are now all awake
        <br />
        <br />
        Though them
        <br />
        Wanted
        <br />
        To stay there not seeing the light
        <br />
        Though them
        <br />
        Wanted
        <br />
        To stay there, bad news took them all out
        <br />
        <br />
        The pain comes along
        <br />
        With all it's wrath and anger
        <br />
        She's trying to kill
        <br />
        What made it come by
        <br />
        But the truth hits hard, there is no way around it
        <br />
        Lying on the floor can't forget anything
        <br />
        <br />
        No! No!! Should have told you not to go!
        <br />
        No! Why! Why the hell did you go out and die!
        <br />
      </p>
    </React.Fragment>
  );

  const acm_story = (
    <React.Fragment>
      All she hears is loud music and noise stuck in her head. She can’t make it
      stop. She closes her eyes wishing to fall asleep. At least for a while.
      The only thing she sees are strange yellow blobs floating in her brain and
      changing their brightness. Like dancing in a thick pool of nothing.
      <br />
      <br />
      <span className="dialog">“Go away…”</span>
      <br />
      The song in her head is getting louder and her body feels like it has been
      invaded by the loud noises. <br />
      <br />
      <span className="dialog">“What’s happening to me?”</span>
      <br />
      She asks herself while trying to get up from bed. She manages to get up
      and decides to go to the bathroom. Stands in front of the mirror and
      splashes some water on her face. She sees her face but can’t recognize it,
      she sees the water but can’t feel it. It’s like she is looking at herself
      from a distance while she is standing just in front of her.
      <br />
      <br />
      <span className="dialog">
        “What’s happening to me?!! What’s this!! Who is this?”
      </span>
      <br />
      She’s scared. She’s losing it. Gladly her body was so tired that it just
      switched off. It's a way of getting some rest...
      <br />
    </React.Fragment>
  );
  const acm_lyrics = (
    <React.Fragment>
      <p>
        Wish I knew why i'm so tired
        <br />
        Surely I wouldn’t sleep
        <br />
        The day grows old
        <br />
        And I’m still in bed <br />
        Not worried if I’m not getting up <br />
        And it’s wrong.
        <br />
        Something must be wrong <br />
        <br />
        Wish I knew why I can’t sleep <br />
        Surely I would wake up <br />
        The night grows old <br />
        And I’m still standing <br />
        Not worried if I won’t rest any more <br />
        <br />
        And it’s wrong. <br />I know it’s wrong
      </p>
    </React.Fragment>
  );

  const da_story = (
    <React.Fragment>
      <p>
        Sara is nearly unconscious. Like hypnotized. Like a puppet. living
        without the strong will that made her who she used to be.
        <br />
        There is a strange creature looking after her. Thin and tall. Ugly. Each
        time her feelings start to boil up this creature comes to her. Smiling.
        <br />
        <br />
        <span className="dialog">
          “Shush little one, everything is ok. You don’t need to suffer anymore,
          I’ll take care of you”.. Shhhh… There you go….”
        </span>
        <br />
        It caresses her and puts her in the stale state it wants her to be.
        <br />
        The creature doesn’t want her to leave. It needs her where she is right
        now. And to stay put. Nothing to disturb her…
        <br />
        <br />
        <span className="dialog">“SILENCE!!”</span>
        <br />
        Sarah kind of likes this “caring” the creature is giving her. It makes
        her feel numb, which is what she feels she wants. But it doesn’t keep
        the loving and sweet feelings to pop out every now and then, a glimpse
        of hope and happiness.
        <br />
        <br />
        <span className="dialog">
          “Shhhh my dear, you don’t need this. You need to rest, shhhh, here…”
        </span>
        <br />
        The fire burning inside of Sara is too strong. There’s people who love
        and need her. She wants to live! She fights off the creature in a moment
        of clarity.
        <br />
        The creature approaches her with its smiles and words, with the promise
        of a peaceful life. It is tempting, but Sara does not accept it. She
        can’t accept it. It is her fate that she needs to accept, and move on.
        <br />
        <br />
        Sara wakes up.
      </p>
    </React.Fragment>
  );
  const da_lyrics = (
    <React.Fragment>
      <p>
        And just with a little smile
        <br />
        You turn around and the puppet starts to cry
        <br />
        With a little smile
        <br />
        You look at it and then it sleeps for a while
        <br />
        The falling light dreams, can make it stop from whining
        <br />
        Don't wake up, 'cause if do so you may start leaving
        <br />
        <br />
        Please need some silence
        <br />
        <br />
        Now but the smile never leaves
        <br />
        It just stands there taking care of your dreams
        <br />
        You know it will not leave
        <br />
        So you breathe low and have nothing to fear
        <br />
        But time's running out
        <br />
        Smoke makes you breath uneasy
        <br />
        Must go now, fate is something you may not start regretting
        <br />
        <br />
        Now but you were not aware
        <br />
        It's doing something you thought it wouldn't dare
        <br />
        You were not aware, that when you turned around it wasn't there
        <br />
        It left you alone
        <br />
        Can believe that it's laughing?
        <br />
        Tears can be spared
        <br />
        When something you could find will truly be smiling
        <br />
      </p>
    </React.Fragment>
  );

  const td_story = (
    <React.Fragment>
      <p>
        Memories… Now that’s all Sara has left of David. Every morning she sees
        him lying beside her when she opens her eyes. She could see where he
        was, she could see his smile… she could touch him.
        <br />
        After a short while that sense of happiness disappears. Reality kicks in
        again.
        <br />
        <br />
        <span className="dialog">
          “Oh… well… good morning nonetheless my love”
        </span>
        <br />
        Sara does everything she is supposed to do during the day. Work, chores.
        Tries to spend as much time as she can with the kids. She couldn’t
        imagine how it was for them. How they felt.
        <br />
        When the day is almost over Sara feels a slight relief. When
        everything’s dark she can be wherever she wants and dream whatever she
        needs. She can feel David again while she hugs his pillow.
        <br />
        <br />
        <span className="dialog">“Still smells like him…”</span>
        <br />
        She floats with rejoice in a world of their favorite things. She is at
        peace for a while.
        <br />
      </p>
    </React.Fragment>
  );
  const td_lyrics = (
    <React.Fragment>
      <p>
        Open my eyes, light's all around <br />
        I love light, I can see <br />
        Can see where you are, can touch you <br />
        <br />
        See your beauty and happiness <br />
        Talk and laugh <br />
        Looking each others eyes, I feel fine <br />
        <br />
        What I most love of light <br />
        It's that I know darkness comes <br />
        Instead of seeing you I feel you in silence <br />
        <br />
        Holding you and sleeping <br />
        Flying through the green sky <br />
        dancing with air <br />
        We look like one <br />
        Just like darkness
        <br />
      </p>
    </React.Fragment>
  );

  const d_story = (
    <React.Fragment>
      “Daddy is now a star in the sky”, says Sara to her kids.
      <br />
      <br />
      Tina, the little girl, goes out everyday when it starts to get dark to
      look for her dad. She speaks to him and listens to him though he’s not
      with them anymore…
      <br />
      <br />
      It’s time for dinner and all three of them sit by the table. The table was
      set for four people, probably out of habit. Sara wants to burst in tears
      when she sees that empty space, but manages to fight the urge back. The
      little boy does not speak much yet. But still asks for his “dada” every
      now and then.
      <br />
      <br />
      When Tina starts feeling sad she looks at her mom and feels comfort. Feels
      safe. Her little brother is always happy and helps her forget the strange
      feeling she has and helps her play for a while.
      <br />
      <br />
      Sometimes she forgets though and asks her dad to read her favourite book
      before she goes to sleep. Or laughs while watching their favourite film
      and looks in his direction to share the laughter. When this happens she
      just feels she wants to be with him and hug him.
      <br />
      <br />
      But she can’t.
      <br />
      <br />
      At the end she looks at the stars and knows that they will be together
      again. She’ll become a star someday after all. She just has to wait.
      <br />
    </React.Fragment>
  );
  const d_lyrics = (
    <React.Fragment>
      Time comes
      <br />
      When one’s left behind, to its own
      <br />
      Left forever
      <br />
      <br />
      Sad is
      <br />
      Now my loneliness
      <br />
      Wish I could
      <br />
      Give you some air
      <br />
      <br />
      And my mother’s here, and it’s soothing
      <br />
      Little brother’s near, and it warms my heart
      <br />
      And though your silent now, still hear you
      <br />
      <br />
      Just want to be with you
      <br />
      Want to hold you
      <br />
      Just want to see you dad
      <br />
      But I can’t
      <br />
      <br />
      Life’s so sleepy now
      <br />
      Time is too still
      <br />
      Guess I’ll just have to wait
      <br />
      <br />
      And so I will
      <br />
    </React.Fragment>
  );

  const rs_story = (
    <React.Fragment>
      <p>
        After the day is over it is time to relax. The chores are done, the kids
        are in bed and the job for the day is over. The only issue is that when
        people get to relax, they have time to think and to feel their life
        again. Sara does not like when the day ends. The haunting feeling of
        horror comes back. She tries to sleep nonetheless, she has to sleep…
        <br />
        <br />
        Her senses are sharp and don’t rest. She hears everything and feels
        everything. The house noises, her body’s noises. All of that goes
        directly to her brain, which makes her body stretch and jump every now
        and then.
        <br />
        <br />
        She has short moments of sleep but wakes up with fear in her eyes.
        Everytime. She begs to get some sleep, she begs. She talks to the ghosts
        surrounding her to please let her be till the morning.
        <br />
        <br />
        <span className="dialog">
          “Take my thoughts with you. Take the night’s noise with you. Let me
          sleep till morning. Please… will you?”
        </span>
        <br />
        Many restless sleep nights came along making her feel guilty, scared and
        helpless. The same ghosts came to visit every night and she begged for
        peace every time.
        <br />
      </p>
    </React.Fragment>
  );
  const rs_lyrics = (
    <React.Fragment>
      <p>
        The night has seen too much
        <br />
        It’s time to sleep now
        <br />
        <br />
        Must get rest this night
        <br />
        Just go away it’s all right
        <br />
        <br />
        Just sleep till dawn
        <br />
        <br />
        Take my thoughts with you
        <br />
        The night’s noise will you?
        <br />
        No more pain till the first light
        <br />
        <br />
      </p>
    </React.Fragment>
  );
  const dotma_story = (
    <React.Fragment>
      <p>
        Sara had a very special dream one night after her body surrendered and
        went to sleep. She met David in her dream. She met him just after the
        moment he was killed. She could see him and herself in this dream, like
        a movie running in her head.
        <br />
        <br />
        It was really quiet and kind of dark. A blue-ish light covered the whole
        scene. He was still on the street he got murdered on, only that he was
        standing and looking around.
        <br />
        <br />
        <span className="dialog">“Where am I, can someone tell me?”</span>
        <br />
        No one told him, of course. In this dream David realized he was no more,
        at least as he understood life worked.
        <br />
        <br />
        Now they were suddenly at their home. Sara saw herself sitting on the
        couch. David crouched in front of her trying to reach her. He spoke to
        Sara, tried to touch her, tried to kiss her… Sara didn’t feel anything
        at all. Sara couldn’t see him nor hear him, but he could see and hear
        her.
        <br />
        <br />
        <span className="dialog">
          “Is this right? Is it ok for this to happen now? Do I have to leave
          everything behind?”
        </span>
        <br />
        Sara saw him make all these questions with no one to answer them.
        Strangely it didn’t feel wrong. It was very strange.
        <br />
        <br />
        The scene changed. It was a really nice day. David stood under the
        sunlight waiting for Sara to come to him. Smiling, always smiling. Sara
        went to him and hugged him. He was warm and soft. It was a really nice
        feeling, one she missed too much. They could see, hear and feel
        eachother now.
        <br />
        <br />
        <span className="dialog">“Hello my love”</span>
        <br />
        Sara looks at him. He has this peaceful and happy expression on his
        face.
        <br />
        <br />
        <span className="dialog">
          “I am going home now. You don’t need to worry and suffer anymore. I
          feel really good!”
        </span>
        <br />
        David’s smile gets wider and his eyes glow with joy.
        <br />
        <br />
        <span className="dialog">
          “I’ll see you there soon! Continue living and be as you are. This is
          just a part of everything. Give my love to the kids!”
        </span>
        <br />
        They have a last hug. Even Sara was happy now. She waves goodbye as
        David disappears into the light. It even felt like she was disappearing
        into it. Like a big window opening over the hole she was in before.
        Peace?
        <br />
        <br />
      </p>
    </React.Fragment>
  );
  const dotma_lyrics = (
    <React.Fragment>
      <p>
        No one will tell me where I went from there
        <br />
        No one will ask me how it feels
        <br />
        No one will see me if I just stood there
        <br />
        No one will hear me if I speak
        <br />
        <br />
        No one will tell me what is wrong with me
        <br />
        No one will tell me maybe it’s right
        <br />
        <br />
        No one will feel me when I touch your face
        <br />
        No one will kiss me back again
        <br />
        No one will hug me when I just need it
        <br />
        No more cold beer with my friends
        <br />
        <br />
        No one will ask me how I really feel
        <br />
        All I can think’s I’m feeling fine
        <br />
        <br />
        Burden, sentence, who can say what’s worse in this whole world
        <br />
        Timing, awakening, how could I have known what was best after all
        <br />
        No pain, no loss, no gain, no fuss
        <br />
        No more fighting
        <br />
        Feels I’m going home
        <br />
        <br />
        Feel’s I’m going home
        <br />
      </p>
    </React.Fragment>
  );
  const fw_story = (
    <React.Fragment>
      <p>
        It was a rough path to acceptance, but, at last, the darkest time is
        over. Of course they still miss their dad. Of course she still longs for
        her husband. There was a lot of suffering and a lot to learn.
        <br />
        <br />
        Life flies by and we make many mistakes and we never get to know enough
        to avoid them. Maybe that’s what life is all about after all. Maybe we
        never get to know everything about someone and some things. And it is
        very nice to have someone to go through life with, sharing doubts and
        certainties.
        <br />
        <br />
        Nothing lasts forever and it is good to know that; accept it. It is ok
        to imagine and dream about the things we want and to believe in them. It
        is awesome to believe in a last goodbye and, thus, live it. A goodbye
        full of feelings and wisdom and epicness!
        <br />
        <br />
        Sara managed to have her last goodbye in her own way. She was at peace
        with the tragedy that they went through. She was ready to move on.
        <br />
        <br />
      </p>
    </React.Fragment>
  );
  const fw_lyrics = (
    <React.Fragment>
      <p>
        Life flew by, can’t say I have learned enough
        <br />
        Can’t say I know what it was about
        <br />
        Tell me something, something true
        <br />
        Tell me things I thought I knew
        <br />
        <br />
        Life flew by, can’t say that I know your mind
        <br />
        Can’t say I won’t leave you behind
        <br />
        Tell me something, something now
        <br />
        To do things I thought I knew how
        <br />
        <br />
        Gently my hand runs through your hair
        <br />
        Feeling it’s the last time I’ll ever be
        <br />
        This close to you again
        <br />
        <br />
        To be in your arms,feel your smile
        <br />
        Is all I that I need to feel that I'm ok
        <br />
        <br />
        And now it's time
        <br />
        for me to leave so I bid you farewell
        <br />
        Remembering the lights in our eyes
        <br />
        <br />
        Farewell
        <br />
        My dearest love, sweetest friend
        <br />
        My heart and my thoughts are yours forever more
        <br />
        <br />
        Goodbye
        <br />
      </p>
    </React.Fragment>
  );
  const t_story = (
    <React.Fragment>
      <p>
        Tina made a new drawing. And everyone was in it!
        <br />
        <br />
        She’s so happy with it that decides to hang it her bedroom to look at it
        everyday. *smiles*
        <br />
      </p>
    </React.Fragment>
  );
  const t_lyrics = (
    <React.Fragment>
      <p>
        My dreams are so sweet
        <br />
        When you sing to me before I go to sleep
        <br />
        <br />
        Choices are easy
        <br />
        When I look up to you and ask you what you think
        <br />
        <br />
        And so glad am I<br />
        To have this good hearts caring after me
        <br />
        <br />
        And now we’re apart
        <br />
        But we’ll meet again
        <br />
        <br />
        Meet again
        <br />
        <br />
        Someday
        <br />
        <br />
      </p>
    </React.Fragment>
  );

  return (
    <div className="App">
      {/* <div className="mftb-header" /> */}
      <div className="mftb_header_image">
        <Image src={mftb_header} />
      </div>
      <div className="mftb_header_image_mobile">
        <Image src={mft_header_mobile} size="medium" centered />
      </div>
      <div className="page_content">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home
                tsoat1Ref={tsoat1Ref}
                tsoat2Ref={tsoat2Ref}
                setScrollToRef={setScrollToRef}
              />
            </Route>
            <Route
              exact
              path="/three-sides-of-a-tragedy-i/was-better-before"
            >
              <SongStory
                story_text={wbb_text}
                lyrics={wbb_lyrics}
                nextUrl="wrong-place-wrong-time"
                prevUrl=""
                cover_image={wbb}
                title="Was Better Before"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=3115578987/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked"
              />
            </Route>
            <Route
              exact
              path="/three-sides-of-a-tragedy-i/wrong-place-wrong-time"
            >
              <SongStory
                story_text={wpwt_text}
                lyrics={wpwt_lyrics}
                nextUrl="unpleasantly-surprised"
                prevUrl="was-better-before"
                cover_image={wpwt}
                title="Wrong Place, Wrong Time"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=952172074/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked"
              />
            </Route>

            <Route
              exact
              path="/three-sides-of-a-tragedy-i/unpleasantly-surprised"
            >
              <SongStory
                story_text={us_text}
                lyrics={us_lyrics}
                nextUrl="last-regret"
                prevUrl="wrong-place-wrong-time"
                cover_image={us}
                title="Unpleasantly Surprised"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=2226809566/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked"
              />
            </Route>

            <Route exact path="/three-sides-of-a-tragedy-i/last-regret">
              <SongStory
                story_text={lr_text}
                lyrics={lr_lyrics}
                nextUrl="vanishing"
                prevUrl="unpleasantly-surprised"
                cover_image={lr}
                title="Last Regret"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=1249034896/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked"
              />
            </Route>

            <Route exact path="/three-sides-of-a-tragedy-i/vanishing">
              <SongStory
                story_text={v_text}
                nextUrl="ignorance"
                prevUrl="last-regret"
                cover_image={v}
                title="Vanishing"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=1423972142/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>

            <Route exact path="/three-sides-of-a-tragedy-i/ignorance">
              <SongStory
                story_text={i_text}
                lyrics={i_lyrics}
                nextUrl="a-calm-journey"
                prevUrl="vanishing"
                cover_image={i}
                title="Ignorance"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=2028055211/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked"
              />
            </Route>

            <Route exact path="/three-sides-of-a-tragedy-i/a-calm-journey">
              <SongStory
                story_text={acj_text}
                lyrics={acj_lyrics}
                nextUrl=""
                prevUrl="ignorance"
                cover_image={acj}
                title="A Calm Journey"
                album="Three Sides of a Tragedy I"
                artist="Man from the Box"
                scrollTo="tsoat1"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=1100195030/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked"
              />
            </Route>

            <Route
              exact
              path="/three-sides-of-a-tragedy-ii/high-level-of-paranoia"
            >
              <SongStory
                story_text={hlop_story}
                lyrics={hlop_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/a-confused-mind"
                prevUrl=""
                cover_image={hlop}
                title="High Level of Paranoia"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/b112853270gcol=ffffff/linkcol=0687f5/artwork=none/track=343514062/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/a-confused-mind">
              <SongStory
                story_text={acm_story}
                lyrics={acm_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/depression-aid"
                prevUrl="/three-sides-of-a-tragedy-ii/high-level-of-paranoia"
                cover_image={acm}
                title="A Confused Mind"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=680633434/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/depression-aid">
              <SongStory
                story_text={da_story}
                lyrics={da_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/to-darkness"
                prevUrl="/three-sides-of-a-tragedy-ii/a-confused-mind"
                cover_image={da}
                title="Depression Aid"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=765906400/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/to-darkness">
              <SongStory
                story_text={td_story}
                lyrics={td_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/dad"
                prevUrl="/three-sides-of-a-tragedy-ii/depression-aid"
                cover_image={td}
                title="To Darkness"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=4222319701/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/dad">
              <SongStory
                story_text={d_story}
                lyrics={d_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/restless-sleep"
                prevUrl="/three-sides-of-a-tragedy-ii/to-darkness"
                cover_image={d}
                title="Dad"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=3112853270/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/restless-sleep">
              <SongStory
                story_text={rs_story}
                lyrics={rs_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/dreams-of-the-moment-after"
                prevUrl="/three-sides-of-a-tragedy-ii/dad"
                cover_image={rs}
                title="Restless Sleep"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=2869878304/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route
              exact
              path="/three-sides-of-a-tragedy-ii/dreams-of-the-moment-after"
            >
              <SongStory
                story_text={dotma_story}
                lyrics={dotma_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/farewell"
                prevUrl="/three-sides-of-a-tragedy-ii/dad"
                cover_image={dotma}
                title="Dreams of the Moment After"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=2485818734/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/farewell">
              <SongStory
                story_text={fw_story}
                lyrics={fw_lyrics}
                nextUrl="/three-sides-of-a-tragedy-ii/thanks"
                prevUrl="/three-sides-of-a-tragedy-ii/dreams-of-the-moment-after"
                cover_image={fw}
                title="Farewell"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=2417505305/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route exact path="/three-sides-of-a-tragedy-ii/thanks">
              <SongStory
                story_text={t_story}
                lyrics={t_lyrics}
                nextUrl=""
                prevUrl="/three-sides-of-a-tragedy-ii/farewell"
                cover_image={t}
                title="Thanks"
                album="Three Sides of a Tragedy II"
                artist="Man from the Box"
                scrollTo="tsoat2"
                setScrollToRef={setScrollToRef}
                storyTopRef={storyTopRef}
                bc_scr="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=small/bgcol=ffffff/linkcol=0687f5/artwork=none/track=693842660/transparent=true/"
                albumUrl="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii"
              />
            </Route>
            <Route path="*">
              <div>Page Not found</div>
            </Route>
          </Switch>
        </Router>
      </div>
      <div className="mftb-footer" id="subscribe">
        <Divider />
        <h1 className="title">Subscribe to my mailing list!</h1>
        <div className="meta">
          Every now and then I'll send you an email with news related to the
          project.
        </div>
        <div className="subscribe_form">
          <MailchimpSubscribe url="https://proudmusicstudios.us18.list-manage.com/subscribe/post?u=f503592bde05b6ea7746991b3&amp;id=ff7c3c97a5" />
        </div>

        <SocialFollow />
      </div>
    </div>
  );
}

export default App;
