import * as React from "react";
import { NavLink } from "react-router-dom";
import { Divider, Image } from "semantic-ui-react";
import YtPlayer from "./YtPlayer";
import tsoat1 from "../images/ThreeSidesOfATragedy_I.jpg";
import tsoat2 from "../images/tsoat2.jpg";

interface IHome {
  tsoat1Ref: any;
  tsoat2Ref: any;
  setScrollToRef(toRef: string): void;
}

const Home: React.FC<IHome> = ({ setScrollToRef, tsoat1Ref, tsoat2Ref }) => {
  return (
    <>
      {/* <div className="side_column"></div> */}

      <div className="column">
        <h1 className="title">Welcome to the "Man from the Box" project</h1>
        <div className="text_block">
          <p>
            Man from the Box is a progressive and experimental rock solo project
            (solo for the time being) and I am focused on a “rock opera” called
            Three Sides of a Tragedy. The progress is a bit slow but steady, as
            I am doing everything by myself. The awesome illustrations published
            here are not made by me. Those are made by skilled artists who I
            hire (new illustrations coming – depending on my financial
            situation). If you’d like to be in the loop and get the news about
            the project and new music, you can subscribe to my newsletter (at
            the end of this page) and get some info everynow and then. So, thank
            you very much for being here and I hope you enjoy the music and the
            story!
            <br />
            <br />
            /Lukas
            <br />
            <br />
          </p>
        </div>
        <h1 className="title">Three Sides of a Tragedy - Overview</h1>
        <div className="text_block">
          <p>
            Populism, corruption, and crime have the power of destroying the
            lives of whole nations. Although it’s hard to foresee precisely how
            politics could wreck an entire country, and more so, the dramatic
            impact it would carry on its citizens, it's clear that violence is
            one of the direct outcomes.
            <br />
            Extreme violence messes people up; it leads to misery and despair.
            <br />
            The good life people once had, is suddenly turned into a living
            hell, and sometimes, they don’t even want to believe it.
            <br />
            The cities they once loved turn into cold, ruthless, and awful
            creatures. Those who have never faced it think of this as the
            product of a fruitful imagination.
            <br />
            It’s easy to think of it as pure fiction, but ‘Reality’ is always
            darker and scarier; this is the reality for millions of people
            worldwide.
            <br />
            <br />I want to help raise awareness on the matter by contrasting
            the most beautiful and ugliest human creations: art and violence.
          </p>
        </div>
        <h1 className="title">Latest Release!</h1>
        <div className="text_block">
          <p>
            Decided to release the first song for Three Sides of a Tragedy III. So here it is! I also published it in <a
              href="https://soundcloud.com/man-from-the-box"
              rel="noreferrer"
              target="_blank"
            >
              SoundCloud
            </a>
          </p>
        </div>
        <div className="album_container">
          <iframe title="Rough Beginning by Man from the Box on Spotify" src="https://open.spotify.com/embed/album/0WVh4eDQhidq575wxHsOQ4?utm_source=generator&theme=0" width="350px" height="442px" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
          <iframe title="Rough Beginning by Man from the Box on Bandcamp" width="350px" height="442px" src="https://bandcamp.com/EmbeddedPlayer/track=2439164015/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless><a href="https://manfromthebox.bandcamp.com/track/rough-beginning">Rough Beginning by Man from the Box</a></iframe>
        </div>
        <div ref={tsoat1Ref}></div>
        <h1 className="title">Three Sides of a Tragedy I</h1>
        <div className="meta">
          David is going out tonight. A well deserved rest from work and
          routine. He just did not notice the City was hunting him…
        </div>
        <Divider hidden />
        <Image src={tsoat1} size="large" centered />
        <Divider hidden />
        <NavLink
          className="listen_story"
          to="/three-sides-of-a-tragedy-i/was-better-before"
          onClick={() => setScrollToRef("story_top")}
        >
          Click to read the story / lyrics while listening
        </NavLink>
        <Divider horizontal>Or listen here</Divider>
        <div className="album_container">
          <iframe
            title="Three Sides of a Tragedy I - Reworked by Man from the Box"
            className="bandcamp_player"
            src="https://bandcamp.com/EmbeddedPlayer/album=2145736782/size=large/bgcol=ffffff/linkcol=0687f5/transparent=true/"
            seamless
          >
            <a href="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-i-reworked">
              Three Sides of a Tragedy I - Reworked by Man from the Box
            </a>
          </iframe>
        </div>
        <h2 className="title">Some videos for this part</h2>
        <div className="videos">
          <YtPlayer url="https://www.youtube.com/embed/ma7nPuk60yc" />
          <YtPlayer url="https://www.youtube.com/embed/fExvqreJji0" />
          <YtPlayer url="https://www.youtube.com/embed/ra7nisfI2NE" />
          <YtPlayer url="https://www.youtube.com/embed/_cMzHlXeMKM" />
          <YtPlayer url="https://www.youtube.com/embed/khitXzcz_cI" />
        </div>

        <div ref={tsoat2Ref}></div>
        <h1 className="title">Three Sides of a Tragedy II</h1>
        <div className="meta">
          Sarah's deepest fears came true. Now it's time to face the devastating
          consequences...
        </div>
        <Divider hidden />
        <Image src={tsoat2} size="large" centered />
        <Divider hidden />
        <NavLink
          className="listen_story"
          to="#"
          onClick={() => setScrollToRef("story_top")}
        >
          <NavLink
            className="listen_story"
            to="/three-sides-of-a-tragedy-ii/high-level-of-paranoia"
            onClick={() => setScrollToRef("story_top")}
          >
            Click to read the story / lyrics while listening
          </NavLink>
        </NavLink>
        <Divider horizontal>or listen here</Divider>
        <div className="album_container">
          <iframe
            title="Three Sides of a Tragedy II by Man from the Box"
            className="bandcamp_player"
            src="https://bandcamp.com/EmbeddedPlayer/album=2234371476/size=large/bgcol=ffffff/linkcol=0687f5/transparent=true/"
            seamless
          >
            <a href="https://manfromthebox.bandcamp.com/album/three-sides-of-a-tragedy-ii">
              Three Sides of a Tragedy II by Man from the Box
            </a>
          </iframe>
        </div>

        <h2 className="title">Some videos for this part</h2>
        <div className="videos">
          <YtPlayer url="https://www.youtube.com/embed/CtIaB1_gPk8" />
          <YtPlayer url="https://www.youtube.com/embed/UWI4oYH5KoU" />
        </div>

        <h1 className="title">Three Sides of a Tragedy III</h1>
        <div className="meta">
          Evil is always lurking. It just needs a little push to show itself...
        </div>
        <Divider hidden />
        <div className="text_block">
          <p>
            The work for this part is ongoing. I already have some ideas about
            the music and would need to hire an illustrator for the art work.
            I'll be done when I'm done :)
            <br />
            <br />
            My intention is to post here some song ideas so you can listen and
            give me feedback, it would be wonderful to have you onboard!
            <br />
            <br />
          </p>
        </div>
      </div>
      {/* <div className="side_column"></div> */}
    </>
  );
};

export default Home;
